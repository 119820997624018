export const MainNavigationItem = [
  {
    id: 1,
    title: "All Photos",
    link: "/"
  },
  {
    id: 2,
    title: "Add New Photos",
    link: "new-photos"
  },
  {
    id: 3,
    title: "Favorites",
    link: "favorites"
  },
];
